import React from "react";

import Header from "./components/header";
import Section from "./components/section";
import works from "./data.json";
import styles from "./styles";

const App = () => (
  <div id="main">
    <Header />
    {works.map((work) => (
      <Section key={work.id} {...work} />
    ))}
    <div className={styles.bottom}>
      <h2>Other clients I have worked for:</h2>
      <p>
        Apple, The Football Association, Genomics England, Stannah, Premier
        League, UEFA, E-on, Sky
      </p>
      <a href="#main">Back to top</a>
    </div>
  </div>
);

export default App;
