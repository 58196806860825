import { css } from "emotion";

export default {
  root: css`
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    z-index: 2;
    font-family: "Madimi One", sans-serif;
    font-weight: 400;
    font-style: normal;

    @media (min-width: 900px) {
      position: fixed;
    }
  `,

  intro: css`
    text-align: center;

    a {
      text-decoration: none;
    }

    @media (min-width: 500px) {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 50%;
      text-align: left;
    }
  `,

  name: css`
    font-size: 30px;
    line-height: 1;

    @media (min-width: 500px) {
      font-size: 30px;
    }
  `,

  title: css`
    font-size: 16px;
    display: none;

    @media (min-width: 500px) {
      font-size: 18px;
      display: block;
    }
  `,

  navigation: css`
    text-align: center;
    padding: 0;
    margin: 10px 0;
    font-size: 15px;
    line-height: 1;

    li {
      display: inline-block;
      margin-right: 10px;
    }

    li:last-child {
      margin-right: 0;
    }

    @media (min-width: 500px) {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 50%;
      text-align: right;
      font-size: 16px;
      margin: 0;
    }
  `,
};
