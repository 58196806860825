import React from "react";

import publicPath from "../../utils/publicPath";
import styles from "./styles";

const Section = ({ id, image, title, company, client, stack, link, color }) => (
  <div id={`section-${id}`} className={styles.root(color)}>
    <div className={styles.image(publicPath(image))}>
      <div className={styles.left}>
        <h2>{title}</h2>
        <p>{company}</p>
        {client && <p>{client}</p>}
      </div>
      <div className={styles.right}>
        <h2>Tech stack</h2>
        <p>{stack}</p>
        {link && (
          <p>
            <a href={link} target="_blank" rel="noreferrer noopener">
              See project
            </a>
          </p>
        )}
      </div>
    </div>
  </div>
);

export default Section;
