import { css } from "emotion";

export default {
  root: (color) => css`
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: ${color};

    h2 {
      font-family: "Madimi One", sans-serif;
    }

    @media (min-width: 600px) {
      background-size: 350px;
    }

    @media (min-width: 1200px) {
      display: flex;
      flex-direction: row;
      background-size: 400px;
      background-attachment: fixed;
      background-position: 50% 50%;

      &:after {
        content: "➳";
        position: fixed;
        left: 50%;
        bottom: 15px;
        width: 30px;
        margin-left: -20px;
        font-size: 30px;
        transform: rotate(90deg);
      }
    }

    @media (min-width: 1600px) {
      background-size: 500px;
    }
  `,

  image: (url) => css`
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-image: url(${url});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 250px;
    transition: background-size 0.5s;

    @media (min-width: 600px) {
      background-size: 350px;
    }

    @media (min-width: 1200px) {
      display: flex;
      flex-direction: row;
      background-size: 400px;
      background-attachment: fixed;
    }

    @media (min-width: 1600px) {
      background-size: 500px;
    }
  `,

  left: css`
    position: absolute;
    width: 100%;
    top: 12%;
    left: 50%;
    font-size: 70%;
    text-align: center;
    transform: translateX(-50%);

    h2 {
      font-size: 1.2rem;
    }

    @media (min-width: 1200px and max-width: 1460px) {
      text-align: left;
      display: block;
      width: 350px;
    }

    @media (min-width: 1200px) {
      display: block;
      position: relative;
      font-size: 100%;
      transform: none;
      left: auto;
      top: auto;
      text-align: right;
      width: 350px;

      p {
        font-size: 0.9rem;
      }
    }

    @media (min-width: 1330px) {
      padding-left: 5%;
    }
  `,

  right: css`
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 12%;
    font-size: 80%;
    text-align: center;

    h2 {
      display: none;
    }

    @media (min-width: 1200px and max-width: 1460px) {
      text-align: right;
    }

    @media (min-width: 1200px) {
      position: relative;
      left: auto;
      bottom: auto;
      font-size: 100%;
      text-align: left;
      display: block;
      justify-self: flex-end;
      margin-left: auto;
      width: 350px;

      h2 {
        font-size: 1.1rem;
        display: block;
      }

      p {
        font-size: 0.9rem;
      }
    }

    @media (min-width: 1330px) {
      padding-right: 5%;
    }
  `,
};
