import { css } from "emotion";

const section = css`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h2 {
    font-family: "Madimi One", sans-serif;
    font-size: 1.3rem;
  }

  p {
    max-width: 600px;
    width: 80vw;
    margin-top: 20px;
  }

  a {
    margin-top: 50px;
  }
`;

export default {
  top: css`
    ${section};
    background-color: #fdf2ff;
  `,
  bottom: css`
    ${section};
    background-color: #9bb8c5;

    a {
      position: relative;

      &:after {
        content: "➳";
        position: absolute;
        transform: rotate(-90deg);
        font-size: 30px;
        width: 30px;
        top: 30px;
        left: 28px;
      }
    }
  `,
};
